<template>
  <div>
    <v-card class="mt-2 ml-2 mr-2">
      <v-card-title>
        <span class="blue--text text-h6"> Saha Ekle</span>
      </v-card-title>

      <v-divider />
      <v-card-text>
        <div class="col-md-12">
          <v-btn
            class="ma-2"
            outlined
            color="indigo"
            dense
            @click="createFarm"
          >
            <v-icon>mdi-plus</v-icon>
            Saha Ekle
          </v-btn>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :items-per-page.sync="itemPerPage"
            class="elevation-1"
            dense
          >
            <template v-slot:item.status="{item}">
              <Chips
                :payload="{
                  color: item.status === 'Active' ? 'green' : 'red',
                  text: item.status === 'Active' ? 'Aktif' : 'Pasif',
                  textColor: 'white',
                  small: true
                }"
              />
            </template>
            <template v-slot:item.prefix="{item}">
              <v-btn
                class="mr-4"
                color="blue darken-1"
                text
                dense
                outlined
                @click="editItemOpen(item)"
              >
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-btn>

              <v-btn
                class="mr-4"
                color="blue darken-1"
                text
                dense
                outlined
                @click="deleteItem(item)"
              >
                <v-icon small>
                  mdi-delete
                </v-icon>
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                dense
                outlined
                @click="farmSettings(item)"
              >
                Saha Yönetimi
              </v-btn>
            </template>

            <template v-slot:item.NominalPlantPower="{item}">
              <span>
                {{ item.NominalPlantPower.value }}
                {{ item.NominalPlantPower.unit }}
              </span>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>

    <Dialog
      v-model="dialog"
      :persistent="true"
      width="90%"
      max-width="1200px"
    >
      <Card>
        <v-card-title class="text-h5 ">
          Saha Ekle
          <v-spacer />
          <Button
            :elevation="0"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </Button>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-tabs
            v-model="tab"
            centered
            icons-and-text
          >
            <v-tabs-slider />

            <v-tab href="#tab-1">
              Genel
            </v-tab>

            <v-tab href="#tab-2">
              Konum
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-col cols="12 d-flex">
                <v-col cols="5">
                  <!--Şirket seç:-->
                  <v-select
                    v-model="companyCode"
                    label="Şirket Seç:"
                    dense
                    class="  mb-0 mt-0 pt-0 pb-0"
                    attach=""
                    outlined
                    :items="companyList"
                  />

                  <!--Saha:-->
                  <v-text-field
                    ref="farmName"
                    v-model="farmdata.farmName"
                    label="Saha:"
                    class="  mb-0 mt-0 pt-0 pb-0"
                    outlined
                    dense
                    hidden-footer
                    hidden-details
                    hidden-hint
                    required
                  />

                  <v-autocomplete
                    v-model="farmdata.timezone"
                    label="Yerel Saat:"
                    dense
                    class="  mb-0 mt-0 pt-0 pb-0"
                    attach=""
                    outlined
                    :items="timezoneslist"
                    item-text="text"
                    item-value="value"
                    return-object
                  />

                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="farmdata.startdatatime"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="farmdata.startdatatime"
                        label="Tarih"
                        readonly
                        v-bind="attrs"
                        hide-details
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="farmdata.startdatatime"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Kapat
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(farmdata.startdatatime)"
                      >
                        Seç
                      </v-btn>
                    </v-date-picker>
                  </v-menu>

                  <v-switch
                    v-model="farmstatus"
                    inset
                    label="Santral Durumu"
                  />
                </v-col>
                <v-col cols="7">
                  <div class="d-flex ml-0 mb-0 mt-0 pt-0 pb-0">
                    <v-col
                      cols="6"
                      class="pl-0 mb-0 mt-0 pt-0 pb-0"
                    >
                      <!--Elektrik fiyatı-->
                      <v-text-field
                        v-model="farmdata.income.electricityPrice"
                        label="Elektirik Fiyatı:"
                        outlined
                        dense
                        class="mb-0 mt-0 pt-0 pb-0 mr-1"
                        hidden-footer
                        hidden-details
                        hidden-hint
                        required
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      class="ml-0 mb-0 mt-0 pt-0 pb-0"
                    >
                      <!--Elektrik fiyatı Para birimi-->
                      <v-select
                        v-model="farmdata.income.currency"
                        label="Elektrik fiyatı Para birimi:"
                        dense
                        class="ml-0 mb-0 mt-0 pt-0 pb-0"
                        attach=""
                        outlined
                        :items="currencys"
                      />
                    </v-col>
                  </div>

                  <div class="d-flex ml-0 mb-0 mt-0 pt-0 pb-0">
                    <v-col
                      cols="6"
                      class="pl-0 mb-0 mt-0 pt-0 pb-0"
                    >
                      <!--CO2 Emission Reduction Coefficient-->
                      <v-text-field
                        v-model="farmdata.CO2EmissionReductionCoefficient.value"
                        label="CO2 Emission Reduction Coefficient:"
                        outlined
                        dense
                        class="mb-0 mt-0 pt-0 pb-0 mr-1"
                        hidden-footer
                        hidden-details
                        hidden-hint
                        required
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      class="ml-0 mb-0 mt-0 pt-0 pb-0"
                    >
                      <!--Birimi-->
                      <v-text-field
                        v-model="farmdata.CO2EmissionReductionCoefficient.unit"
                        label="Birimi:"
                        outlined
                        dense
                        class="ml-0 mb-0 mt-0 pt-0 pb-0"
                        hidden-footer
                        hidden-details
                        hidden-hint
                        required
                      />
                    </v-col>
                  </div>

                  <div class="d-flex ml-0 mb-0 mt-0 pt-0 pb-0">
                    <v-col
                      cols="6"
                      class="pl-0 mb-0 mt-0 pt-0 pb-0"
                    >
                      <!--Kurulu Güç:-->
                      <v-text-field
                        v-model="farmdata.NominalPlantPower.value"
                        label="Kurulu Güç:"
                        outlined
                        dense
                        class="mb-0 mt-0 pt-0 pb-0 mr-1"
                        hidden-footer
                        hidden-details
                        hidden-hint
                        required
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      class="ml-0 mb-0 mt-0 pt-0 pb-0"
                    >
                      <!--Kurulu Güç Birimi-->
                      <v-text-field
                        v-model="farmdata.NominalPlantPower.unit"
                        label="Kurulu Güç Birimi:"
                        outlined
                        dense
                        class="ml-0 mb-0 mt-0 pt-0 pb-0"
                        hidden-footer
                        hidden-details
                        hidden-hint
                        required
                      />
                    </v-col>
                  </div>

                  <div class="d-flex ml-0 mb-0 mt-0 pt-0 pb-0">
                    <v-col
                      cols="6"
                      class="pl-0 mb-0 mt-0 pt-0 pb-0"
                    >
                      <!--Modül Sayısı:-->
                      <v-text-field
                        v-model="farmdata.modulCount"
                        label="Modül Sayısı:"
                        outlined
                        dense
                        class="mb-0 mt-0 pt-0 pb-0 mr-1"
                        hidden-footer
                        hidden-details
                        hidden-hint
                        required
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      class="ml-0 mb-0 mt-0 pt-0 pb-0"
                    >
                      <!--Modül Alanı:-->
                      <v-text-field
                        v-model="farmdata.moduleArea"
                        label="Modül Alanı:"
                        outlined
                        dense
                        class="ml-0 mb-0 mt-0 pt-0 pb-0"
                        hidden-footer
                        hidden-details
                        hidden-hint
                        required
                      />
                    </v-col>
                  </div>
                </v-col>
              </v-col>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-col cols="12 d-flex">
                <v-col cols="6">
                  <!--Ülke:-->
                  <v-text-field
                    ref="Country"
                    v-model="farmdata.Country"
                    label="Ülke:"
                    class=" mb-0 mt-0 pt-0 pb-0"
                    outlined
                    dense
                    hidden-footer
                    hidden-details
                    hidden-hint
                    required
                  />
                  <!--Şehir:-->
                  <v-text-field
                    ref="City"
                    v-model="farmdata.City"
                    label="Şehir"
                    class="  mb-0 mt-0 pt-0 pb-0"
                    outlined
                    dense
                    hidden-footer
                    hidden-details
                    hidden-hint
                    required
                  />
                  <!--Adres:-->
                  <v-text-field
                    ref="adress"
                    v-model="farmdata.adress"
                    label="Adres:"
                    class="  mb-0 mt-0 pt-0 pb-0"
                    outlined
                    dense
                    hidden-footer
                    hidden-details
                    hidden-hint
                    required
                  />

                  <div class="d-flex">
                    <!--<div class="col-6 mb-0 mt-0 pt-0 pb-0">Konum enlem:</div>
                    <div class="col-6 mb-0 mt-0 pt-0 pb-0">Konum boylam:</div>-->
                  </div>
                  <div class="d-flex">
                    <v-text-field
                      ref="location.lat"
                      v-model="farmdata.location.lat"
                      label="Konum enlem:"
                      class="col-6 mb-0 mt-0 pt-0 pb-0"
                      outlined
                      dense
                      hidden-footer
                      hidden-details
                      hidden-hint
                      required
                    />

                    <v-text-field
                      ref="location.lon"
                      v-model="farmdata.location.lng"
                      label="Konum boylam:"
                      class="col-6 mb-0 mt-0 pt-0 pb-0 ml-1"
                      outlined
                      dense
                      hidden-footer
                      hidden-details
                      hidden-hint
                      required
                    />
                  </div>
                </v-col>

                <v-col cols="6">
                  <div id="map" />
                </v-col>
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            dense
            outlined
            @click="dialog = false"
          >
            Kapat
          </v-btn>
          <v-btn
            v-if="farmEdit === 'false'"
            color="blue darken-1"
            text
            dense
            outlined
            @click="setfunc"
          >
            Kayıt
          </v-btn>

          <v-btn
            v-if="farmEdit === 'true'"
            color="blue darken-1"
            text
            dense
            outlined
            @click="editFarm"
          >
            Güncelle
          </v-btn>
        </v-card-actions>
      </Card>
    </Dialog>
    <Snackbar
      :notify="notify"
    />
  </div>
</template>
<script>
import { getCompanyList1 } from '@/api/Request/company';

import {
  setFarmCreate, setFarmEdit, setFarmDel, getCompanyFarmLists,
} from '@/api/Request/farm';
import { init } from '@/utils/gmaps';
import moment from 'moment';
import helper from '@/api/helper';

const moments = require('moment-timezone');

const timeZones = moments.tz.names();
const TIMEZONES = [];

// eslint-disable-next-line no-restricted-syntax, guard-for-in
for (const i in timeZones) {
  const obj = {
    text: timeZones[i],
    value: `${moment.tz(timeZones[i]).format('Z')}-${timeZones[i]}`,
  };
  TIMEZONES.push(obj);
}

export default {
  data: () => ({
    CO2EmissionReductionCoefficientValue: '',
    CO2EmissionReductionCoefficientUnit: '',
    switch1: true,
    switch2: false,
    desserts: [],
    itemPerPage: 15,
    dialog: false,
    menu: false,
    modal: false,
    menu2: false,
    search: '',
    company_name: '',
    valid: true,
    lazy: true,

    edit: false,
    editdialog: false,
    farmstatus: true,
    farmdata: {},
    currencys: ['TL', 'USD', 'EUR'],
    date: null,
    result: null,

    timezoneslist: [],
    interval: null,
    electricityPriceCurrency: '',
    farmEdit: 'false',
    tab: null,
    companyCode: null,
    companyData: {},
    companyList: [],
    companyCodeList: [],
    notify: {
      snackbar: false,
      text: '',
      vertical: true,
      color: 'success',
    },
  }),
  computed: {

    headers() {
      return [
        { text: 'Şirket Adı', value: 'companyName' },
        { text: 'Saha Adı', value: 'farmName' },
        { text: 'Kurulu Güç', value: 'NominalPlantPower' },
        { text: 'Şehir', value: 'City' },
        { text: 'Durumu', value: 'status' },
        { text: '', value: 'prefix' },
      ];
    },
    statusList() {
      return [
        { text: 'Aktif', value: 'Active' },
        { text: 'Pasif', value: 'Passive' },
      ];
    },

  },
  watch: {
    tab() {
      if (this.tab === 'tab-2') {
        this.initMap();
      }
    },
    farmstatus() {
      this.farmdata.status = this.farmstatus === true ? 'Active' : 'Passive';
    },
  },
  created() {
    this.timezoneslist = TIMEZONES;
    this.newFarmdata();
  },
  mounted() {
    this.getCompanyLists();
  },

  methods: {
    setStatus(item) {
      return item === 'Active' ? 'Aktif' : 'Pasif';
    },
    farmSettings(item) {
      const storage = window.localStorage;
      const temp = {
        companyCode: item.companyCode,
        prefix: item.prefix,
      };
      const encoded = btoa(JSON.stringify(temp));
      this.$store.dispatch('setCompany', temp);
      storage.setItem('user_company', encoded);

      this.$router.push({
        name: 'adminstationsantral',
      });
    },
    closeDialog() {
      this.dialog = false;
      this.newFarmdata();
    },
    deleteItem(item) {
      const params = {
        condiniton: {
          companyCode: item.companyCode,
          prefix: item.prefix,
        },
      };
      setFarmDel(params).then(() => {
        setTimeout(() => {
          this.getCompanyLists();
        }, 500);
      });
    },

    getCompanyLists() {
      const params = {};
      getCompanyList1(params).then((res) => {
        if (helper.resIsEmpty(res)) {
          const companyList = [];
          const companyCodeList = [];
          const companyData = {};
          Object.keys(res.data.data).forEach((i) => {
            companyList.push({
              text: res.data.data[i].companyName,
              value: res.data.data[i].companyCode,
            });
            companyCodeList.push(res.data.data[i].companyCode);
            companyData[res.data.data[i].companyCode] = res.data.data[i];
          });

          this.companyList = companyList;
          this.companyData = companyData;
          setTimeout(() => {
            this.getFarm(companyCodeList);
          }, 1000);
        }
      });
    },

    getFarm(companyList) {
      const params = {
        condiniton: {},
      };
      if (companyList.length > 0) {
        params.condiniton.companyCode = companyList;
      }

      getCompanyFarmLists(params).then((res) => {
        if (helper.resIsEmpty(res)) {
          this.desserts = res.data.data;

          if (res.data.success === 'true') {
            if (Array.isArray(res.data.data)) {
              const farms = [];
              Object.keys(res.data.data).forEach((i) => {
                const { companyCode } = res.data.data[i];

                // eslint-disable-next-line no-unused-expressions
                const obj = res.data.data[i];
                if (this.companyData[companyCode]) {
                  obj.companyName = this.companyData[companyCode].companyName;
                }
                farms.push(obj);
              });

              this.desserts = farms;
            }
          }
        }
      });
    },
    santralAdd(item) {
      this.$router.push(`/farm/${item.companyCode}/santral/${item.prefix}`);
    },

    setfunc() {
      this.farmdata.companyCode = this.companyCode;

      const params = {
        condiniton: {
          companyCode: this.companyCode,
        },
        data: this.farmdata,
      };

      setFarmCreate(params).then((res) => {
        if (res.data.success === 'false') {
          this.notify.snackbar = true;
          this.notify.text = 'Saha oluştururken hata oldu. ';
          this.notify.vertical = true;
          this.notify.color = 'red';
        }
        if (res.data.success === 'true') {
          this.notify.snackbar = true;
          this.notify.text = 'Saha oluşturuldu.';
          this.notify.vertical = true;
          this.notify.color = 'success';
        }
        setTimeout(() => {
          this.getCompanyLists();
        }, 500);
      });
    },
    createFarm() {
      this.newFarmdata();
      this.dialog = true;
      this.farmEdit = 'false';
    },
    newFarmdata() {
      this.farmdata = {
        farmName: '',

        location: {
          lat: null,
          lng: null,
        },
        status: true,
        companyCode: '',

        adress: '',
        Country: '',
        City: '',
        timeInterval: '',
        startdatatime: moment().format('YYYY-MM-DD'),
        modulCount: '',
        moduleArea: '',
        timezone: '+03:00-Europe/Istanbul',
        CO2EmissionReductionCoefficient: {
          unit: '',
          value: '',
        },
        income: {
          electricityPrice: '',
          currency: '',
        },
        NominalPlantPower: {
          unit: '',
          value: '',
        },
      };
    },
    editItemOpen(item) {
      this.companyCode = item.companyCode;
      this.tab = null;
      this.farmdata = item;
      this.farmEdit = 'true';
      this.dialog = true;
      this.farmstatus = item.status === 'Active';
    },
    editFarm() {
      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.farmdata.prefix,
        },
        data: this.farmdata,
      };
      setFarmEdit(params).then((res) => {
        if (res.data.success === 'false') {
          this.notify.snackbar = true;
          this.notify.text = 'Saha bilgisi güncellenemedi. ';
          this.notify.vertical = true;
          this.notify.color = 'red';
        }
        if (res.data.success === 'true') {
          this.notify.snackbar = true;
          this.notify.text = 'Saha bilgisi güncellendi.';
          this.notify.vertical = true;
          this.notify.color = 'success';
        }
        this.getCompanyLists();
      });
    },

    async initMap() {
      const self = this;
      this.google = await init();
      const options = {
        zoom: 14,
        center: { lat: 39.7, lng: 29.9 },
        gestureHandling: 'greedy',
      };
      const myLatlng = { lat: 39.7, lng: 29.9 };
      if (self.farmdata.location.lat != null) {
        myLatlng.lat = parseFloat(self.farmdata.location.lat);
        options.center.lat = parseFloat(self.farmdata.location.lat);
      }
      if (self.farmdata.location.lat != null) {
        myLatlng.lng = parseFloat(self.farmdata.location.lng);
        options.center.lng = parseFloat(self.farmdata.location.lng);
      }

      const { google } = this;
      this.map = new google.maps.Map(document.getElementById('map'), options);
      // Create the initial InfoWindow.
      let infoWindow = new google.maps.InfoWindow({
        content: self.farmdata.farmName,
        position: myLatlng,
      });

      infoWindow.open(this.map);

      // Configure the click listener.
      this.map.addListener('click', (mapsMouseEvent) => {
        // Close the current InfoWindow.

        const temp = mapsMouseEvent.latLng.toJSON();
        infoWindow.close();

        self.farmdata.location.lat = temp.lat;
        self.farmdata.location.lng = temp.lng;

        // Create a new InfoWindow.
        infoWindow = new google.maps.InfoWindow({
          position: mapsMouseEvent.latLng,
          // myfarm.location.lan=
        });
        infoWindow.setContent(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2));
        infoWindow.open(this.map);
      });
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
#map {
  height: 400px;
  width: 100%;
}
</style>
